import React, {memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types';

import { useParams } from "react-router-dom";
import ShareOrgModal from "../../workspaceDatabase/shareOrgModal";
import {
  addDbInUserThunk,
  removeDbInUserThunk,
  updateAccessOfUserInDbThunk,
} from "../../../store/allTable/allTableThunk";
import { customUseSelector } from "../../../store/customUseSelector";

function Sharedb(props) {
  const {setOpenShareDb,openShareDb}=props;
  const params = useParams();
  const adminId = customUseSelector((state) => state.user.userId);
  const dispatch = useDispatch();
  const userAcess = customUseSelector((state) =>state.tables?.userAcess);
  // const userDetail = customUseSelector((state) =>state.tables?.userDetail);
  const currentUsers = customUseSelector((state) => state.dataBase.currentOrgUsers);
  const allorgss = customUseSelector((state) => state.dataBase.allOrg)
  const currentOrgId = customUseSelector((state) => state.dataBase.currentOrgId);
  const currentOrg  = allorgss.find(org => org.id == currentOrgId);

  const [dbUsers, setDbUsers] = useState();
  const [userType, setUserType] = useState("");

  const orgUsers = Object.keys(userAcess).reduce((acc, key) => {
    if (key in currentUsers) {
        acc[key] = {...currentUsers[key], ...userAcess[key]};
    }
    return acc;
}, {});
// const currentOrg  = allorgss.find(org => org.id == currentOrgId);


  useEffect(() => {
    let arr = [];
    Object.entries(currentUsers).map(([key]) => {
      let obj = {};
      obj[`user_type`] = userAcess[key]?.access;
      obj[`user_id`] = currentUsers[key];
      arr.push(obj);
    });
    let userObj = {
      users: arr,
    };
    
    setDbUsers(userObj);
  }, [currentUsers]);
  

  const sharedatabase = async (email, user_type) => { 
    const data = {
      email: email,
      userAccess: user_type,
    };
    dispatch(addDbInUserThunk({ dbId: params.dbId, adminId, data }));
  };
  const updateUserinsharedb = (id, user_type) => {
    let obj = { ...dbUsers };
    let originalObj={...dbUsers};
    obj.users = obj.users.map((user) => {
      if (user.user_id === id) {
        return { ...user, user_type: user_type };
      }
      return user;
    });
  setDbUsers(obj);
    const data = {
      id: id,
      userAccess: { access: user_type },
    };
    dispatch(updateAccessOfUserInDbThunk({ dbId: params.dbId, adminId, data })).then(e=>{
      if(e.type.includes('rejected'))
      {
setDbUsers(originalObj);
      }
    });
  };
  const removeUserFromDb = (email, userId) => {
    const data = {
      email: email,
      id:userId
    };
  
    dispatch(removeDbInUserThunk({ dbId: params.dbId, adminId, data, userId }));
  };
  return (
    <div>
   

     {openShareDb  && <ShareOrgModal
        title={"Share Database"}
        useCase={"sharedb"}
        shareOrg={openShareDb}
        setShareOrg={setOpenShareDb}
        userType={userType}
        setUserType={setUserType}
        org={currentOrg}
        orgUsers = {orgUsers}
        setOrg={setDbUsers}
        shareWorkspace={sharedatabase}
        updateUserTypeInOrg={updateUserinsharedb}
        removeUserFromWorkspace={removeUserFromDb}
      />}
    </div>
  );
}

Sharedb.propTypes = {
  openShareDb: PropTypes.any,
  setOpenShareDb:PropTypes.any,
};
export default memo(Sharedb);
